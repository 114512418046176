export const ALL_MERCHANT_HANDLER = 'ALL_MERCHANT_HANDLER';
export const ALL_MERCHANT_STATUS_NOT_IN = 'ALL_MERCHANT_STATUS_NOT_IN_HANDLER';
export const ALL_MERCHANT_BY_STATUS = 'ALL_MERCHANT_BY_STATUS_HANDLER';
export const ALL_USER_HANDLER = 'ALL_USER_HANDLER';
export const ALL_DEVICE_HANDLER = 'ALL_DEVICE_HANDLER';
export const ALL_SESSION_HANDLER = 'ALL_SESSION_HANDLER';
export const ALL_BILLER_DETAIL_HANDLER = 'ALL_BILLER_DETAIL_HANDLER';
export const ALL_PAY_ACCOUNR_HANDLER = 'ALL_PAY_ACCOUNR_HANDLER';
export const ALL_USER_DEVICE_HANDLER = 'ALL_USER_DEVICE_HANDLER';
export const TABLE_RECORD = 'TABLE_RECORD';
export const ALL_BILLER_REPORT = 'ALL_BILLER_REPORT';
export const ALL_REFUND_TRANSACTION = 'ALL_REFUND_TRANSACTION';
export const ALL_CARD_TOKEN= 'ALL_CARD_TOKEN';
export const ALL_SALESMAN= 'ALL_SALESMAN';
export const ALL_USERNAMES= 'ALL_USERNAMES';
export const ALL_BILLERNAMES= 'ALL_BILLERNAMES';
export const ALL_MERCHANT_NAMES= 'ALL_MERCHANT_NAMES';
export const ALL_IPG_REFERENCES = 'ALL_IPG_REFERENCES';
export const ALL_MERCHANTS_OF_SALESMAN = 'ALL_MERCHANTS_OF_SALESMAN';
export const ALL_ADMIN_ROLES = 'ALL_ADMIN_ROLES';
export const ALL_BILLER_MERCHANTS = 'ALL_BILLER_MERCHANTS';
export const ALL_INTEGRATE_BM = 'ALL_INTEGRATE_BM';
export const ALL_BM_TRANSACTION = 'ALL_BM_TRANSACTION';
export const ALL_BM_FILTER_DETAIL = 'ALL_BM_FILTER_DETAIL';
export const TABLE_LOADER = 'TABLE_LOADER';
