import * as actionType from './../action/actionTypes';

const initials ={
  merchants:[],
  merchants_not_in_status : [],
  merchants_by_status : [],
  users:[],
  session:[],
  biller_details:[],
  pay_account:[],
  user_devices:[],
  total_record:0,
  biller_report:[],
  refund:[],
  card_token:[],
  salesman:[],
  userName:[],
  billerName:[],
  merchantName:[],
  ipgReference:[],
  merchantOfSalesman:[],
  adminRoles:[],
  billerMerchants:[],
  integrateAcctBM:{},
  bm_transaction:[],
  bm_filterDetails:{},
  tLoader:false,
};

const retrieveReducer=(state=initials, action)=>{

    switch (action.type) {

      case actionType.ALL_MERCHANT_HANDLER:
        return{
          ...state,
         merchants:action.value
        };

      case actionType.ALL_MERCHANT_STATUS_NOT_IN:
        return{
          ...state,
          merchants_not_in_status:action.value
        };

      case actionType.ALL_MERCHANT_BY_STATUS:
        return{
          ...state,
          merchants_by_status:action.value
        };

      case actionType.ALL_USER_HANDLER:
        return{
          ...state,
          users:action.value
        };

      case actionType.ALL_SESSION_HANDLER:
        return{
          ...state,
          session:action.value
        };

      case actionType.ALL_BILLER_DETAIL_HANDLER:
        return{
          ...state,
          biller_details:action.value
        };

      case actionType.ALL_PAY_ACCOUNR_HANDLER:
        return{
          ...state,
         pay_account:action.value
        };

      case actionType.ALL_USER_DEVICE_HANDLER:
        return{
          ...state,
          user_devices:action.value
        };

      case actionType.TABLE_RECORD:
        return{
          ...state,
          total_record:action.value
        };

      case actionType.ALL_BILLER_REPORT:
        return{
          ...state,
          biller_report:action.value
        };

      case actionType.ALL_REFUND_TRANSACTION:
        return{
          ...state,
          refund:action.value
        };
      case actionType.ALL_CARD_TOKEN:
        return{
          ...state,
          card_token:action.value
        };

      case actionType.ALL_SALESMAN:
        return{
          ...state,
          salesman:action.value
        };

      case actionType.ALL_USERNAMES:
        return{
          ...state,
          userName:action.value
        };

      case actionType.ALL_BILLERNAMES:
        return{
          ...state,
          billerName:action.value
        };

      case actionType.ALL_MERCHANT_NAMES:
        return{
          ...state,
          merchantName:action.value
        };

      case actionType.ALL_IPG_REFERENCES:
        return{
          ...state,
          ipgReference:action.value
        };

      case actionType.ALL_MERCHANTS_OF_SALESMAN:
        return{
          ...state,
          merchantOfSalesman:action.value
        };

      case actionType.ALL_ADMIN_ROLES:
        return{
          ...state,
          adminRoles:action.value
        };

      case actionType.ALL_BILLER_MERCHANTS:
        return{
          ...state,
          billerMerchants:action.value
        };

      case actionType.ALL_INTEGRATE_BM:
        return{
          ...state,
          integrateAcctBM:action.value
        };

      case actionType.ALL_BM_TRANSACTION:
        return{
          ...state,
          bm_transaction:action.value
        };

      case actionType.ALL_BM_FILTER_DETAIL:
        return{
          ...state,
          bm_filterDetails:action.value
        };

      case actionType.TABLE_LOADER:
        return{
          ...state,
          tLoader:action.value
        };

      default:
        return state;
    }

};

export default retrieveReducer;
