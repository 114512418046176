import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {createStore} from 'redux';
import 'semantic-ui-css/semantic.min.css';
import 'antd/dist/antd.css';
// import axios from 'axios';
import {applyMiddleware, combineReducers, compose} from "redux";
import thunk from 'redux-thunk';
import retrieveReducer from './store/reducer/RetrieveReducer'



 // axios.defaults.baseURL='http://3.0.81.200/api2';
 // axios.defaults.baseURL='http://localhost:8081';


const rootReducer=combineReducers({
  retrieveReducer: retrieveReducer
});


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
